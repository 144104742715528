import React, {useEffect, useState} from "react";
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from "react-i18next";
import {ReactComponent as Agreement} from "../../images/incomeTable/Agreement.svg"
import {ReactComponent as Upload} from "../../images/incomeTable/upload.svg"
import FileUploaderModal from "./FileUploader";
import {ReactComponent as Withdraw} from '../../images/Verification/checkTrue.svg'
import {ReactComponent as Decline} from '../../images/Agreement/decline.svg'
import {ReactComponent as Pending} from '../../images/Verification/pending.svg'
import {ReactComponent as Send} from '../../images/Agreement/send.svg'
import Button from '@mui/material/Button';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from "react-router-dom";

function ProjectAgreement() {

    const [content, setContent] = useState([])
    const [pageSettings, setPageSettings] = useState({})
    const [page, setPage] = useState(0)
    const [row, setRow] = useState([])
    const [pagination, setPagination] = useState();
    const pageSize = 10;
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [idDoc, setIdDoc] = useState('')
    const {t, i18n} = useTranslation();

    const [openReason, setOpenReason] = useState(false);
    const [currentReason, setCurrentReason] = useState('');

    const [openRevoke, setOpenRevoke] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [invoiceAlreadyCreated, setInvoiceAlreadyCreated] = useState(false);

    const [currentId, setCurrentId] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        retrieveProjectAgreements()
    }, [page])

    const retrieveProjectAgreements = async () => {

        const param = {
            page: page,
            size: 10,
        }

        const queryString = new URLSearchParams(param).toString();
        const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + '/project' + `?${queryString}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setContent(response.data.content)
            setPagination(response.data.totalElements);
        } catch (error) {
            console.error(error)
        }

    }

    //Форматирование даты
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };


    // Открытие PDF


    const handleOpenAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }

// Загрузить файл

    const openModal = (id) => {
        setIsModalOpen(true);
        setIdDoc(id)
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIdDoc('')
    };

    const manageStatusClick = (agreement) => {
        if (agreement.status === 'CONFIRMED') {
            handleGetInvoice(agreement)
        } else if (agreement.status === 'DECLINED') {
            handleOpenReason(agreement);
        } else if(agreement.status === 'PENDING_CONFIRMATION') {
            handleOpenRevoke(agreement.id)
        } else if (agreement.status === 'PENDING_SIGNING') {
            handleOpenDelete(agreement.id)
        }
    }

    const handleRevokeAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + `/revoke/${id}`;
        try {
            await axios.post(apiUrl, {}, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            }).then(() => {
                setOpenRevoke(false);
                retrieveProjectAgreements();
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + `/${id}`;
        try {
            await axios.delete(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            }).then(() => {
                setOpenDelete(false);
                retrieveProjectAgreements();
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getStatusMessage = (status) => {
        switch (status) {
            case 'PENDING_SIGNING':
                return <Send style={{width: '25px', height: '25px'}}/>;
            case 'PENDING_CONFIRMATION':
                return <Pending style={{width: "25px", height: '25px'}}/>;
            case 'CONFIRMED':
                return <Withdraw style={{width: "25px", height: '25px'}}/>;
            case 'DECLINED':
                return <Decline style={{width: "25px", height: '25px'}}/>;
            default:
                return 'Unknown status.';
        }
    };

    const handleGetInvoice = async (agreement) => {
        if (await isReadyForInvoiceByAgreementId(agreement)) {
            const state = {
                withInvoiceModal: true,
                withConsultantChecked: false,
                withProjectChecked: true,
                agreement: agreement
            };
            navigate('../invoice', {state});
        } else {
            setInvoiceAlreadyCreated(true);
        }
    }

    const isReadyForInvoiceByAgreementId = async (agreement) => {
        const param = {
            currency: agreement.currency
        }
        const queryString = new URLSearchParams(param).toString();
        const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + `/ready-for-invoice/${agreement.id}?${queryString}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            return response.data.exists
        } catch (error) {
            console.error(error)
        }
    }

    const handleOpenReason = (agreement) => {
        setCurrentReason(agreement.reason);
        setCurrentId(agreement.id)
        setOpenReason(true);
    }

    const handleCloseReason = () => {
        setCurrentReason('');
        setOpenReason(false);
    }

    const handleOpenRevoke = (id) => {
        setCurrentId(id)
        setOpenRevoke(true);
    }

    const handleOpenDelete = (id) => {
        setCurrentId(id)
        setOpenDelete(true);
    }

    const handleCloseRevoke = () => {
        setOpenRevoke(false);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    }

    const handleCloseInvoiceAlreadyCreated = () => {
        setInvoiceAlreadyCreated(false);
    }

    const sendAnotherConfirmation = () => {
        openModal(currentId);
        handleCloseReason()
    }

    return (
        <div className="mainPageBody important-margin-top">
            <span style={{fontSize: "30px"}}>{t("AgreementsProjectTitle")}</span>
            <div className="wrapperIncomeTableTransaction">
                <TableContainer component={Paper} style={{boxShadow: "none"}}>
                    <Table
                        aria-label="simple table">
                        <TableHead
                            style={{background: "#F7F9FC", border: "1px solid white"}}
                        >
                            <TableRow style={{border: "none"}}>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementProjectName")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementTime")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementAmount")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementCurrency")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("TemplateFile")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("MainPageTransactionsDocUpload")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("status")}</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {content.length > 0 && content.map((row) => (
                                <TableRow
                                    key={row.is}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.projectName}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {formatDate(row.dateTime)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#10B981",
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        align="left"
                                    >
                                        {row.amount}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#10B981",
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.currency}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "center",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Agreement
                                            style={{
                                                width: '25px',
                                                height: '25px'
                                            }}
                                            onClick={() => handleOpenAgreement(row.templateUrlId)}
                                        />
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "center",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {(row.status === 'PENDING_SIGNING') ? (
                                                <Button>
                                                    <Upload
                                                        style={{
                                                            width: '25px',
                                                            height: '25px'
                                                        }}
                                                        onClick={() => openModal(row.id)}
                                                    />
                                                </Button>
                                            )
                                            :
                                            (
                                                <Agreement
                                                    style={{
                                                        width: '25px',
                                                        height: '25px'
                                                    }}
                                                    onClick={() => handleOpenAgreement(row.confirmationUrlId)}
                                                />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "center",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Button onClick={() => manageStatusClick(row)}>
                                            {getStatusMessage(row.status)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={false}
                        component="div"
                        count={pagination}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </TableContainer>
            </div>
            <FileUploaderModal isOpen={isModalOpen} onRequestClose={closeModal} id={idDoc}
                               reloadPage={retrieveProjectAgreements}/>
            <Dialog open={openReason} onClose={handleCloseReason} maxWidth="lg">
                <DialogTitle>
                    {t('Reason')}:
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                    <textarea
                        value={currentReason}
                        disabled
                        style={{width: '500px', height: '100px'}}>
                    </textarea>
                    <Button onClick={() => sendAnotherConfirmation()}>
                        {t('SendAnotherConfirmation')}
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={openRevoke} onClose={handleCloseRevoke} maxWidth="lg">
                <DialogTitle>
                    {t('DeletingSignConfirm')}
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                    <Button onClick={() => handleRevokeAgreement(currentId)}>
                        {t('DeleteButton')}
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={openDelete} onClose={handleCloseDelete} maxWidth="lg">
                <DialogTitle>
                    {t('DeleteAgreementConfirm')}
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                    <Button onClick={() => handleDeleteAgreement(currentId)}>
                        {t('DeleteButton')}
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={invoiceAlreadyCreated} onClose={handleCloseInvoiceAlreadyCreated} maxWidth="lg">
                <DialogTitle>
                    {t('InvoiceAlreadyCreated')}
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                    <Button onClick={() => handleCloseInvoiceAlreadyCreated()}>
                        {t('BillsModalWindowButton')}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ProjectAgreement