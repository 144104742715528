import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import "../../Style/Modal/SiteSettingsModal.scss"
import "../../Style/Invoice/InvoiceModal.scss"
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import {useTranslation} from 'react-i18next';
import {Checkbox, FormControlLabel, FormGroup, styled} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {ReactComponent as Agreement} from "../../images/incomeTable/Agreement.svg"
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";

const InvoiceModal = ({isOpen, onClose, withConsultantChecked, withProjectChecked, agreement}) => {

    const [content, setContent] = useState([])
    const [currency, setCurrency] = useState('EUR')
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [includedAgreements, setIncludedAgreements] = useState([]);
    const [feeRestriction, setFeeRestriction] =
        useState({restrictionStatus: 'ALLOWED', restrictionString: 'allowed'});
    const [currencies, setCurrencies] = React.useState([]);
    const [currentWallet, setCurrentWallet] = useState('');

    const [consultantFee, setConsultantFee] = useState(0);
    const [activationCommission, setActivationCommission] = useState(0);
    const [transactionCommission, setTransactionCommission] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (agreement) {
            console.log('agreement', agreement);
            setCurrency(agreement.currency)
            setIncludedAgreements([agreement]);
        }
    }, [agreement]);

    // Use useEffect to update invoiceTypes based on props
    useEffect(() => {
        const types = [];
        if (withConsultantChecked) {
            types.push('CONSULTANT');
        }
        if (withProjectChecked) {
            types.push('PROJECT');
        }
        setInvoiceTypes(types);
    }, [withConsultantChecked, withProjectChecked]); // Dependency array

    useEffect(() => {
        if (isOpen && currency) {
            retrieveCurrenciesWithWallets();
            retrieveConsultantFee();
            retrieveActivationCommission();
            retrieveTransactionalCommission();
            retrieveCurrentWallet();

            retrieveReadyForInvoiceAgreements();
            getConsultantFeeRestriction();
        }

    }, [isOpen, currency]);

    useEffect(() => {
        if (includedAgreements) {
            retrieveInvoiceCost();
        }
        console.log('new', includedAgreements);
    }, [invoiceTypes, includedAgreements]);

    const retrieveCurrentWallet = async () => {
        if (currency) {
            const apiUrl = urlFromTemplate(ENDPOINTS.WALLET) + `/${currency}`
            try {
                const response = await axios.get(apiUrl, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Access-Control-Allow-Credentials": "true",
                    },
                })
                setCurrentWallet(response.data.number)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const retrieveCurrenciesWithWallets = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.WALLET) + '/currencies/with-wallet'
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setCurrencies(response.data.currencies);
        } catch (error) {
            console.error(error);
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const StyledSelect = styled(Select)({
        width: '60px',
        borderRadius: "10px",
        border: 'none',
        // height: "50px",
        backgroundColor: "white",
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
        },
    });

    const StyledMenuItem = styled(MenuItem)({
        borderRadius: "10px",
    });

    const handleCurrencyChange = async (event) => {
        if (event.target.value === "USD") {
            setCurrency('USD')
        } else if (event.target.value === 'EUR') {
            setCurrency('EUR')
        } else if (event.target.value === 'USDT') {
            setCurrency('USDT')
        }
        setInvoiceTypes((prevInvoiceTypes) => prevInvoiceTypes.filter(type => type !== 'PROJECT'))
    }

    // Открытие PDF
    const handleOpenAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }

    const getConsultantFeeRestriction = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + '/fee-restriction';
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setFeeRestriction({
                restrictionStatus: response.data.restrictionStatus,
                restrictionString: response.data.restrictionString
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleFormDataChange = (e) => {
        const {name, checked} = e.target;

        // Update invoiceTypes
        setInvoiceTypes((prevInvoiceTypes) =>
            checked
                ? [...prevInvoiceTypes, name]
                : prevInvoiceTypes.filter(type => type !== name)
        );
    };

    const handleAgreementChange = (e, agreement) => {
        // Update invoiceTypes
        setIncludedAgreements((prevAgreements) =>
            e.target.checked
                ? [...prevAgreements, agreement]
                : prevAgreements.filter(prevAgreement => prevAgreement.id !== agreement.id)
        );
    };

    const retrieveActivationCommission = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + '/commission/activation/' + currency
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            setActivationCommission(response.data.amount)
        } catch (error) {
            console.error(error);
        }
    }

    const retrieveTransactionalCommission = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + '/commission/transaction'
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            setTransactionCommission(response.data.amount)
        } catch (error) {
            console.error(error);
        }
    }

    const retrieveReadyForInvoiceAgreements = async () => {

        const param = {
            currency: currency
        }
        const queryString = new URLSearchParams(param).toString();
        const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + '/ready-for-invoice' + `?${queryString}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setContent(response.data)
            if (agreement && agreement.currency === currency) {
                setIncludedAgreements([agreement]);
                if (!invoiceTypes.includes('PROJECT')) {
                    invoiceTypes.push('PROJECT');
                }
            } else {
                setIncludedAgreements([]);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const retrieveInvoiceCost = async () => {

        const invoice = {currency, invoiceTypes, agreementIds: includedAgreements.map(agreement => agreement.id)};
        const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE) + '/details'
        try {
            const response = await axios.post(apiUrl, invoice, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setTotalCost(response.data.amount)
        } catch (error) {
            console.error(error)
        }
    }

    const retrieveConsultantFee = async () => {

        const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + '/fee/' + currency
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setConsultantFee(response.data.amount)
        } catch (error) {
            console.error(error)
        }
    }

    const isWide = () => {
        return invoiceTypes.includes('PROJECT') && (content.length > 0)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const invoice = {currency, invoiceTypes, agreementIds: includedAgreements.map(agreement => agreement.id)};
        const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE);
        try {
            await axios.post(apiUrl, invoice, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            }).then((response) => {
                if (response.status === 201) {
                    const file = new Blob([response.data], {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    handleCloseModal(e);
                }
            });
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const navigateToWalletPage = () => {
        navigate('../wallet');
    }

    const handleCloseModal = (e) => {
        e.preventDefault();
        setContent([]);
        setTotalCost(0);
        setCurrency('EUR');
        setInvoiceTypes([]);
        setIncludedAgreements([]);
        onClose();
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content" style={{width: isWide() ? '800px' : '400px'}}>
                <label className="modalTitle"> {t('InvoiceModalTitle')} </label>
                {currencies && currencies.length > 0 && <>
                    <div style={{display: 'flex', justifyContent: 'start', width: '95%', alignItems: 'center'}}>
                        <FormControl style={{marginBottom: '10px'}}>
                            <StyledSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currency}
                                onChange={handleCurrencyChange}
                                style={{
                                    borderRadius: "10px",
                                    // height: "50px",
                                    backgroundColor: "white",
                                }}
                            >
                                {currencies.includes("EUR") &&
                                    <StyledMenuItem value={"EUR"}>
                                        {t("CommissionModalWindowWithdrawalCurrencyEuro")}
                                    </StyledMenuItem>
                                }
                                {currencies.includes("USD") &&
                                    <StyledMenuItem value={"USD"}>
                                        {t("CommissionModalWindowWithdrawalCurrencyUsd")}
                                    </StyledMenuItem>
                                }
                                {currencies.includes("USDT") &&
                                    <StyledMenuItem value={"USDT"}>
                                        {t("CommissionModalWindowWithdrawalCurrencyUsdt")}
                                    </StyledMenuItem>
                                }
                            </StyledSelect>
                        </FormControl>
                        {currentWallet &&
                            <span style={{marginLeft: '10px', marginTop: '-13px'}}>{t("WalletNumber")}: {
                                currentWallet.length > 10 ? currentWallet.substring(0, 15) + "..." : currentWallet
                            }</span>
                        }
                    </div>
                    <Box sx={{
                        maxWidth: isWide() ? '800px' : '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }} value='1'>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={invoiceTypes.includes('CONSULTANT')}
                                                   onChange={handleFormDataChange}
                                                   name="CONSULTANT"/>}
                                label={t('ConsultantFee') + (feeRestriction.restrictionStatus === 'ALLOWED' ? '' : ' (' + feeRestriction.restrictionString + ')')}
                                disabled={feeRestriction.restrictionStatus !== 'ALLOWED'}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={invoiceTypes.includes('PROJECT')}
                                                   onChange={handleFormDataChange}
                                                   name="PROJECT"/>}
                                label={content.length === 0 ? t('ProjectContributionsDisabled') : t('ProjectContributions')}
                                disabled={content.length === 0}
                            />
                        </FormGroup>
                        {isWide() && <>
                            <div className="wrapperIncomeTableTransaction">
                                <TableContainer component={Paper} style={{boxShadow: "none"}}>
                                    <Table
                                        aria-label="simple table">
                                        <TableHead
                                            style={{background: "#F7F9FC", border: "1px solid white"}}
                                        >
                                            <TableRow style={{border: "none"}}>
                                                <TableCell
                                                    style={{
                                                        border: "none",
                                                        fontFamily: "Helvetica Neue",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        textAlign: "left",
                                                        color: "#64748B",
                                                    }}
                                                >
                                                    <b>{t("InvoiceInclude")}</b>
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        border: "none",
                                                        fontFamily: "Helvetica Neue",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        textAlign: "left",
                                                        color: "#64748B",
                                                    }}
                                                >
                                                    <b>{t("AgreementProjectName")}</b>
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        border: "none",
                                                        fontFamily: "Helvetica Neue",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        textAlign: "left",
                                                        color: "#64748B",
                                                    }}
                                                >
                                                    <b>{t("AgreementTime")}</b>
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        border: "none",
                                                        fontFamily: "Helvetica Neue",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        textAlign: "left",
                                                        color: "#64748B",
                                                    }}
                                                >
                                                    <b>{t("AgreementAmount")}</b>
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        border: "none",
                                                        fontFamily: "Helvetica Neue",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        textAlign: "left",
                                                        color: "#64748B",
                                                    }}
                                                >
                                                    <b>{t("AgreementCurrency")}</b>
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        border: "none",
                                                        fontFamily: "Helvetica Neue",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        textAlign: "left",
                                                        color: "#64748B",
                                                    }}
                                                    align="left"
                                                >
                                                    <b>{t("TemplateFile")}</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {content.length > 0 && content.map((agreement) => (
                                                <TableRow
                                                    key={agreement.is}
                                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                                >
                                                    <TableCell
                                                        style={{
                                                            paddingLeft: "16px",
                                                            padding: "25px",
                                                            fontFamily: "Helvetica Neue",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            textAlign: "left",
                                                        }}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                checked={includedAgreements.some(currentAgreement => currentAgreement.id === agreement.id)}
                                                                onChange={(e) => handleAgreementChange(e, agreement)}
                                                                id={agreement.id}/>}
                                                            label={''}/>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            paddingLeft: "16px",
                                                            padding: "25px",
                                                            fontFamily: "Helvetica Neue",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            textAlign: "left",
                                                        }}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {agreement.projectName}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            paddingLeft: "16px",
                                                            padding: "25px",
                                                            fontFamily: "Helvetica Neue",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            textAlign: "left",
                                                        }}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {formatDate(agreement.dateTime)}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: "#10B981",
                                                            paddingLeft: "16px",
                                                            padding: "25px",
                                                            fontFamily: "Helvetica Neue",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            textAlign: "left",
                                                        }}
                                                        align="left"
                                                    >
                                                        {agreement.amount}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: "#10B981",
                                                            paddingLeft: "16px",
                                                            padding: "25px",
                                                            fontFamily: "Helvetica Neue",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            textAlign: "left",
                                                        }}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {agreement.currency}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            paddingLeft: "16px",
                                                            padding: "25px",
                                                            fontFamily: "Helvetica Neue",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            textAlign: "left",
                                                        }}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <Agreement
                                                            style={{
                                                                width: '25px',
                                                                height: '25px'
                                                            }}
                                                            onClick={() => handleOpenAgreement(agreement.templateUrlId)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </>
                        }
                    </Box>
                    <Box sx={{
                        maxWidth: isWide() ? '800px' : '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                    }} value='2'>

                        {totalCost > 0 && <>
                            {invoiceTypes.includes('CONSULTANT') &&
                                <div
                                    className="big-price">{t('ConsultantFee') + ': ' + consultantFee + ' ' + currency}</div>
                            }
                            {invoiceTypes.includes('PROJECT') && includedAgreements.map((agreement) => (
                                <>
                                    <div
                                        className="big-price">{t('ProjectContributions') + ': ' + agreement.amount + ' ' + currency}</div>
                                    <div className="small-price">{t('TransactionCommission') + ': ' +
                                        Math.round(agreement.amount * transactionCommission * 100) / 100 + ' ' +
                                        currency + ' (' + transactionCommission * 100 + '%)'}</div>
                                    <div className="small-price">{t('ActivationCommission') + ': '
                                        + activationCommission + ' ' + currency}</div>
                                </>
                            ))}
                            <h4>{t('TotalCost') + ': ' + totalCost + ' ' + currency}</h4>
                        </>}
                    </Box>
                    <Box sx={{
                        maxWidth: isWide() ? '800px' : '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }} value='3'>
                        <div className="siteSettingsButtonContainer">
                            <button className="whiteButton buttonBoldText siteSettingsButton"
                                    style={{marginRight: '10px'}}
                                    onClick={handleCloseModal}>
                                {t('SiteSettingsModalButtonClose')}
                            </button>
                            <button className="yellowButton buttonBoldText siteSettingsButton "
                                    id='siteSettingsButtonSave'
                                    onClick={handleSubmit}>
                                {t('SiteSettingsModalButtonSave')}
                            </button>
                        </div>
                    </Box>
                </>}
                {!currencies || currencies.length === 0 && <>
                    <h3 style={{
                        textAlign: 'center',
                        color: 'rgba(248,67,67,0.99)',
                        margin: '25px 0'
                    }}>{t('NoWalletsInfo')}</h3>
                    <Box sx={{
                        maxWidth: isWide() ? '800px' : '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }} value='3'>
                        <div className="siteSettingsButtonContainer">
                            <button className="whiteButton buttonBoldText siteSettingsButton"
                                    style={{marginRight: '10px'}}
                                    onClick={handleCloseModal}>
                                {t('SiteSettingsModalButtonClose')}
                            </button>
                            <button className="yellowButton buttonBoldText siteSettingsButton "
                                    id='siteSettingsButtonSave'
                                    onClick={navigateToWalletPage}>
                                {t('NavigateToWalletPage')}
                            </button>
                        </div>
                    </Box>
                </>}
            </div>
        </div>
    );
};

export default InvoiceModal;