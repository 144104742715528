import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import { ReactComponent as Home } from "../../images/Navigation/homeNav.svg";
import { ReactComponent as Cabinet } from "../../images/Navigation/cabinetClient.svg";
import { ReactComponent as Profile } from "../../images/Navigation/profileNav.svg";
import { ReactComponent as Bills } from "../../images/Navigation/BillsNav.svg";
import { ReactComponent as Verification } from "../../images/Navigation/verification.svg";
import { ReactComponent as Invoice } from "../../images/Navigation/invoice.svg";
import { ReactComponent as Exit } from "../../images/Navigation/exitNav.svg";
import { ReactComponent as Agreement } from "../../images/incomeTable/Agreement.svg";
import { ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import SiteSettingsModal from "../../Modal/SiteSettingsModal";
import { useTranslation } from "react-i18next";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import { ReactComponent as Settings } from "../../images/header/settings.svg";
import ModalWindowCabinetConsultant from "../../Modal/ModalWindowCabinetConsultant/ModalWindowCabinetConsultant";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "../../Style/Navigation/Navigation.scss";

function NavigationAdaptive({ isOpen, onClose, isClose }) {
  const [activeItem, setActiveItem] = useState("cabinet");
  const [buttonLabel, setButtonLabel] = useState("В Кабинет Партнера");
  const [buttonsTrue, setButtonsTrue] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [siteSettings, setSiteSettings] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countOfUsers, setCountOfUsers] = useState(0);
  const [cabinetModal, setCabinetModal] = useState(false);
  const [formStatus, setFormStatus] = useState(false);
  const [openConsultantAgreements, setOpenConsultantAgreements] =
    useState(false);

  const navigate = useNavigate();
  let params = "";
  const { t, i18n } = useTranslation();

  setTimeout(() => {
    setSiteSettings(JSON.parse(localStorage.getItem("site_settings")));
  }, 200);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
    onClose();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    handleGetCountOfUsers();
    const storedLabel = localStorage.getItem("buttonLabel");
    if (storedLabel) {
      setButtonLabel(storedLabel);
    }
  }, []);

  useEffect(() => {
    params = window.location.pathname;

    switch (params) {
      case "/mainpage":
        setSelectedIndex(1);
        break;
      case "/mainpage/profile/profile":
        setSelectedIndex(2);
        break;
      case "/mainpage/profile/contacts":
        setSelectedIndex(2);
        break;
      case "/mainpage/profile/password":
        setSelectedIndex(2);
        break;
      case "/mainpage/profile/location":
        setSelectedIndex(2);
        break;
      case "/mainpage/profile/notification":
        setSelectedIndex(2);
        break;
      case "/mainpage/profile/verification":
        setSelectedIndex(2);
        break;
      case "/mainpage/profile/telegram":
        setSelectedIndex(2);
        break;
      case "/mainpage/bills":
        setSelectedIndex(3);
        break;
      case "/mainpage/list-operation":
        setSelectedIndex(3);
        break;
      case "/mainpage/consultant-agreement":
        setSelectedIndex(5);
        break;
      case "/mainpage/project-agreement":
        setSelectedIndex(6);
        break;
      case "/mainpage/withdraw-agreement":
        setSelectedIndex(7);
        break;
      case "/mainpage/invoice":
        setSelectedIndex(8);
        break;
      case "/mainpage/verification":
        setSelectedIndex(10);
        break;
    }
  }, []);

  // const handleCabinetChange = () => {
  //   navigate("/consultant");
  // };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onClose();
  };

  const handleGetCountOfUsers = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/count`;
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          setCountOfUsers(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleExit = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/logout`;
    await axios.post(apiUrl, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  };

  // Модальное окно для Кабинета Консультанта
  const handleCabinetChange = () => {
    // navigate('/consultant')
    // setCabinetModal(true)
    handelCheckUserActive();
    onClose();
  };

  const handleClose = () => {
    setCabinetModal(false);
  };

  // Проверка на Активированного пользователя

  const handelCheckUserActive = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACTIVE_STATUS) + `/status`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      console.log(response.data);
      if (response.data.activeStatus === "ACTIVE") {
        navigate("/consultant");
      } else {
        handleCheckForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Проверка на заполнение формы

  const handleCheckForm = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_FORM) + `/status`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      console.log(response.data);
      setFormStatus(response.data.formStatus);
      setCabinetModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isOpen && buttonsTrue && (
        <Drawer
          open={isOpen}
          sx={{
            //   width: drawerWidth,
            backgroundColor: "#1C2434",

            flexShrink: 0,
            "& .MuiDrawer-paper": {
              // width: drawerWidth,
              boxSizing: "border-box",
              position: "static",
              display: "flex",
              flexDirection: "column-reverse",
              backgroundColor: "#1C2434",
              justifyContent: "flex-end",
            },
          }}
          variant="temporary"
          anchor="left"
        >
          <List
            style={{
              width: "100%",
              backgroundColor: "#1C2434",
              color: "white",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <Home
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("lorem")} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
              component={Link}
              to="/mainpage"
            >
              <ListItemIcon>
                <Cabinet
                  style={{
                    width: "20px",
                    height: "20px",
                    stroke: activeItem === "cabinet" ? "red" : "black",
                    marginRight: "10px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("ClientDashboard")} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
              component={Link}
              to="/mainpage/profile/profile"
            >
              <ListItemIcon>
                <Profile
                  style={{
                    width: "25px",
                    height: "25px",
                    fill: "white",

                    marginRight: "10px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("profile")} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
              component={Link}
              to="/mainpage/bills"
            >
              <ListItemIcon>
                <Bills
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: activeItem === "bills" ? "red" : "black",
                    marginRight: "10px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("bills")} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 10}
              onClick={(event) => handleListItemClick(event, 10)}
              component={Link}
              to="/mainpage/verification"
            >
              <ListItemIcon>
                <Verification
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: activeItem === "verification" ? "red" : "black",
                    marginRight: "10px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("verification")} />
            </ListItemButton>
            <ListItemButton
              onClick={() => setOpenConsultantAgreements((prev) => !prev)}
            >
              <ListItemIcon>
                <Agreement
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: activeItem === "agreement" ? "red" : "black",
                    marginRight: "10px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("agreement")} />
              {openConsultantAgreements ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {openConsultantAgreements && (
              <div className="agreementPopUp">
                <ListItemButton
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}
                  component={Link}
                  to="/mainpage/consultant-agreement"
                >
                  <ListItemIcon>
                    <Profile
                      style={{
                        width: "22px",
                        height: "22px",
                        fill: activeItem === "consultant" ? "red" : "black",
                        marginRight: "10px",
                        fill: "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("consultant")} />
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                  component={Link}
                  to="/mainpage/project-agreement"
                >
                  <ListItemIcon>
                    <Cabinet
                      style={{
                        width: "22px",
                        height: "22px",
                        fill: activeItem === "bills" ? "red" : "black",
                        marginRight: "10px",
                        fill: "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("project")} />
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 7}
                  onClick={(event) => handleListItemClick(event, 7)}
                  component={Link}
                  to="/mainpage/withdraw-agreement"
                >
                  <ListItemIcon>
                    <Bills
                      style={{
                        width: "22px",
                        height: "22px",
                        fill: activeItem === "bills" ? "red" : "black",
                        marginRight: "10px",
                        fill: "white",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("withdraw")} />
                </ListItemButton>
              </div>
            )}
            <ListItemButton
              selected={selectedIndex === 8}
              onClick={(event) => handleListItemClick(event, 8)}
              component={Link}
              to="/mainpage/invoice"
            >
              <ListItemIcon>
                <Invoice
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: activeItem === "bills" ? "red" : "black",
                    marginRight: "10px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("invoice")} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 4}
              // onClick={(event) => handleListItemClick(event, 4)}
              onClick={handleExit}
              component={Link}
              to="/registration"
            >
              <ListItemIcon>
                <Exit
                  style={{
                    width: "25px",
                    height: "25px",
                    stroke: activeItem === "exit" ? "red" : "black",
                    marginRight: "10px",
                    stroke: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("exit")} />
            </ListItemButton>
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{ minWidth: "150px", maxWidth: "193px" }}
                className=" yellowButton"
              >
                {t("buttonConnectWallet")}
              </button>
            </ListItem>
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{ minWidth: "150px", maxWidth: "150px" }}
                className="buttonPartnersOffice"
                onClick={handleCabinetChange}
              >
                {t("ConsultantDashboard")}
              </button>
            </ListItem>
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "20%",
                  minWidth: "150px",
                  maxWidth: "150px",
                  color: "white",
                }}
                className="boxCountOfUser"
              >
                <div className="countOfUser">{countOfUsers.count}</div>
                <div className="tooltip"> {t("buttonCountOfUser")} </div>
              </div>
            </ListItem>
            {/* <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{ width: "20%", minWidth: "150px", maxWidth: "150px" }}
                className="buttonSocialNetworkWrapper"
              >
                <div className="iconSocialNetwork">
                  <TelegramIcon />
                </div>
                <span>{t("buttonTelegramHeader")}</span>
              </button>
            </ListItem> */}
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="buttonSettingsWrapper"
                onClick={handleModalOpen}
                style={{ minWidth: "150px", maxWidth: "150px" }}
              >
                <Settings style={{ width: "34px", height: "34px" }} />
              </button>
            </ListItem>
          </List>
          <ClearIcon
            style={{
              color: "white",
              width: "30px",
              height: "30px",
              padding: "20px",
            }}
            onClick={onClose}
          />
        </Drawer>
      )}
      <SiteSettingsModal isOpen={isModalOpen} onClose={handleModalClose} />
      <ModalWindowCabinetConsultant
        isOpen={cabinetModal}
        onClose={handleClose}
        formStatus={formStatus}
      />
    </>
  );
}

export default NavigationAdaptive;
