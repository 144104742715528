import React, {useEffect, useState} from "react";
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from "react-i18next";
import {ReactComponent as Agreement} from "../../images/incomeTable/Agreement.svg"
import {ReactComponent as Upload} from "../../images/incomeTable/upload.svg"
import FileUploaderModal from "./FileUploaderInvoice";
import {ReactComponent as Withdraw} from '../../images/Verification/checkTrue.svg'
import {ReactComponent as Decline} from '../../images/Agreement/decline.svg'
import {ReactComponent as Pending} from '../../images/Verification/pending.svg'
import {ReactComponent as Send} from '../../images/Agreement/send.svg'
import {useLocation} from 'react-router-dom';
import InvoiceModal from "./InvoiceModal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

function InvoiceAgreement() {

    const location = useLocation();
    const {withInvoiceModal, withConsultantChecked, withProjectChecked, agreement} = location.state || {};

    const [content, setContent] = useState([])
    const [page, setPage] = useState(0)
    const [pagination, setPagination] = useState();
    const [userVerified, setUserVerified] = useState(false);
    const pageSize = 10;
    const [isFileUploaderModalOpen, setIsFileUploaderModalOpen] = useState(false)
    const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] = useState(withInvoiceModal)
    const [idDoc, setIdDoc] = useState('')
    const {t, i18n} = useTranslation();

    const [openReason, setOpenReason] = useState(false);
    const [currentReason, setCurrentReason] = useState('');

    const [openRevoke, setOpenRevoke] = useState(false);
    const [openPendingPayment, setOpenPendingPayment] = useState(false);

    const [currentId, setCurrentId] = useState();

    useEffect(() => {
        retrieveInvoices();
        getVerification();
    }, [page])

    const retrieveInvoices = async () => {

        const param = {
            page: page,
            size: 10,
        }

        const queryString = new URLSearchParams(param).toString();
        const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE) + `?${queryString}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setContent(response.data.content)
            setPagination(response.data.totalElements);
        } catch (error) {
            console.error(error)
        }
    }

    const getVerification = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/verified`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setUserVerified(response.data.verified);
        } catch (error) {
            console.error(error);
        }
    };

    //Форматирование даты
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    // Открытие PDF
    const handleOpenAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }

    // Загрузить файл
    const openFileUploaderModal = (id) => {
        setIsFileUploaderModalOpen(true);
        setIdDoc(id)
    };

    const handleCloseFileUploaderModal = () => {
        setIsFileUploaderModalOpen(false);
        setIdDoc('')
    };

    const handleCreateInvoiceClick = () => {
        if (userVerified) {
            setIsCreateInvoiceModalOpen(true);
        } else {
            alert(t('VerificationRequired'));
        }
    };

    const handleInvoiceModalClose = () => {
        setIsCreateInvoiceModalOpen(false);
        retrieveInvoices();
    }

    const getStatusMessage = (status) => {
        switch (status) {
            case 'PENDING_SIGNING':
                return <Send style={{width: '25px', height: '25px'}}/>;
            case 'PENDING_CONFIRMATION':
                return <Pending style={{width: "25px", height: '25px'}}/>;
            case 'CONFIRMED':
                return <Withdraw style={{width: "25px", height: '25px'}}/>;
            case 'DECLINED':
                return <Decline style={{width: "25px", height: '25px'}}/>;
            case 'PENDING_PAYMENT':
                return <Send style={{width: '25px', height: '25px'}}/>;
            default:
                return 'Unknown status.';
        }
    };

    const handleSendInvoice = async (id) => {
        const params = {
            invoiceId: id
        }
        const payload = {}

        const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE) + `/user-confirm/${id}`
        try {
            const response = await axios.post(apiUrl, payload, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
        } catch (error) {
            console.error(error)
        } finally {
            await retrieveInvoices();
            setOpenPendingPayment(false);
            setOpenReason(false);
        }
    }

    const handleOpenReason = (agreement) => {
        setCurrentReason(agreement.reason);
        setCurrentId(agreement.id)
        setOpenReason(true);
    }

    const handleCloseReason = () => {
        setCurrentReason('');
        setOpenReason(false);
    }

    const handleOpenRevoke = (id) => {
        setCurrentId(id)
        setOpenRevoke(true);
    }

    const handleOpenPendingPayment = (id) => {
        setCurrentId(id)
        setOpenPendingPayment(true);
    }

    const handleCloseRevoke = () => {
        setOpenRevoke(false);
    }

    const handleClosePendingPayment = () => {
        setOpenPendingPayment(false);
    }

    const handleRevokeAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE) + `/revoke-confirm/${id}`;
        try {
            await axios.post(apiUrl, {}, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            }).then(() => {
                setOpenRevoke(false);
                retrieveInvoices();
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE) + `/${id}`;
        try {
            await axios.delete(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            }).then(() => {
                setOpenPendingPayment(false);
                retrieveInvoices();
            })
        } catch (error) {
            console.error(error)
        }
    }

    const manageStatusClick = (invoice) => {
        if(invoice.status === 'PENDING_PAYMENT') {
            handleOpenPendingPayment(invoice.id);
        } else if (invoice.status === 'DECLINED') {
            handleOpenReason(invoice);
        } else if (invoice.status === 'PENDING_CONFIRMATION') {
            handleOpenRevoke(invoice.id)
        }
    }

    const truncateWithEllipsis = (text) => {
        const maxLength = 20;
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...'
        } else {
            return text;
        }
    }

    return (
        <div className="mainPageBody important-margin-top">
            <div style={{display: 'flex', justifyContent: 'space-between', width: '95%', alignItems: 'center'}}>
                <span style={{fontSize: "30px"}}>{t("InvoiceTitle")}</span>
                <button className="yellowButton" onClick={handleCreateInvoiceClick}>
                    {t("CreateInvoice")}
                </button>
            </div>
            <div className="wrapperIncomeTableTransaction">
                <TableContainer component={Paper} style={{boxShadow: "none"}}>
                    <Table
                        aria-label="simple table">
                        <TableHead
                            style={{background: "#F7F9FC", border: "1px solid white"}}
                        >
                            <TableRow style={{border: "none"}}>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("SenderWallet")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("ReceiverWallet")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementTime")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementAmount")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementCurrency")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("TemplateFile")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("MainPageTransactionsDocUpload")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("status")}</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {content.length > 0 && content.map((row) => (
                                <TableRow
                                    key={row.is}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        align="left"
                                    >
                                        {truncateWithEllipsis(row.wallet)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        align="left"
                                    >
                                        {truncateWithEllipsis(row.systemWallet)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {formatDate(row.dateTime)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#10B981",
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        align="left"
                                    >
                                        {row.amount}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#10B981",
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.currency}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Agreement
                                            style={{
                                                width: '25px',
                                                height: '25px'
                                            }}
                                            onClick={() => handleOpenAgreement(row.templateUrlId)}
                                        />
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {(row.status === 'PENDING_PAYMENT' || row.status === 'DECLINED') ? (
                                                <Button>
                                                    <Upload
                                                        style={{
                                                            width: '25px',
                                                            height: '25px',
                                                            marginLeft: '29.5px'
                                                        }}
                                                        onClick={() => openFileUploaderModal(row.id)}
                                                    />
                                                </Button>
                                            )
                                            :
                                            (
                                                <Button disabled>
                                                    <Upload
                                                        style={{
                                                            width: '25px',
                                                            height: '25px',
                                                            marginLeft: '29.5px',
                                                            fill: '#AAAAAA'
                                                        }}
                                                    />
                                                </Button>
                                            )
                                        }
                                    </TableCell>

                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Button
                                            disabled={row.status === 'CONFIRMED'}
                                            onClick={() => manageStatusClick(row)}>
                                            {getStatusMessage(row.status)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={false}
                        component="div"
                        count={pagination}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </TableContainer>
            </div>
            <FileUploaderModal isOpen={isFileUploaderModalOpen} onRequestClose={handleCloseFileUploaderModal}
                               id={idDoc}/>
            <InvoiceModal isOpen={isCreateInvoiceModalOpen} onClose={handleInvoiceModalClose}
                          withConsultantChecked={withConsultantChecked} withProjectChecked={withProjectChecked}
                          agreement={agreement}/>/
            />

            <Dialog open={openReason} onClose={handleCloseReason} maxWidth="lg">
                <DialogTitle>
                    {t('Reason')}:
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                    <textarea
                        value={currentReason}
                        disabled
                        style={{width: '500px', height: '100px'}}>
                    </textarea>
                    <Button onClick={() => handleSendInvoice(currentId)}>
                        {t('ResendConfirmation')}
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={openRevoke} onClose={handleCloseRevoke} maxWidth="lg">
                <DialogTitle>
                    {t('DeletingSignConfirm')}
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                    <Button onClick={() => handleRevokeAgreement(currentId)}>
                        {t('DeleteButton')}
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={openPendingPayment} onClose={handleClosePendingPayment} maxWidth="lg">
                <DialogTitle>
                    {t('InvoiceActionTitle')}:
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                    <label style={{fontSize: '12px'}}>{t('InvoiceSubmit')}</label>
                    <label style={{fontSize: '12px'}}>{t('InvoiceDelete')}</label>
                    <div style={{display: 'flex', flexDirection: 'row',
                        justifyContent: 'center', marginTop: '10px'}}>
                        <Button onClick={() => handleSendInvoice(currentId)}>
                            {t('SubmitButton')}
                        </Button>
                        <Button onClick={() => handleDeleteAgreement(currentId)}>
                            {t('DeleteButton')}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default InvoiceAgreement